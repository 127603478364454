import React from 'react';
import "./InfiniteScrollLoading.scss";
import {SpinLoader} from '../../components/Loader';

const InfiniteScrollLoading = ()=>(
    <div className="infinite-scroll-loading">
        <SpinLoader className="small"/>
    </div>
)

export default InfiniteScrollLoading;