import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBadgeCheck,
} from "@fortawesome/pro-duotone-svg-icons";


const CertifiedBadge = ({user,...props})=>{
    const LIST_VARIANT = {
        BASE : {"--fa-primary-color": "#ffffff", "--fa-secondary-color": "#0095ff", "--fa-secondary-opacity": "1", fontSize:'1em', ...props?.style},
        GOLD : {"--fa-primary-color": "#ffffff", "--fa-secondary-color": "#e1c56a", "--fa-secondary-opacity": "1", fontSize:'1em', ...props?.style}
    }
    let currentStyle = LIST_VARIANT.BASE;
    if(user?.isGold){
        currentStyle = LIST_VARIANT.GOLD
    }
    return <FontAwesomeIcon className="certified-badge" icon={faBadgeCheck} style={currentStyle}/>
}

export default CertifiedBadge;

//#d4af37
//#d4bf79
//bdb76b
//daa520
//e8b923
//e1c56a