
import createLink from '../../../../../libs/createLink';
import Drawer from '../../../../components2/Drawer/Drawer';
import Button, { BUTTON_VARIANTS } from '../../../../components2/UI/Button/Button';
import './DrawerLocation.scss';
import locationImg from './location.jpg';

const DrawerLocation = ({ open, handleClose }) => {
    return (
        <Drawer
            small
            open={open} 
            handleClose={handleClose} 

            >
            <div className='drawer-location'>
                <img src={locationImg} alt="Location" style={{width:240, height:'auto'}}/>
                <p>Vous devez renseigner votre localisation dans vos paramètres de rencontre pour utiliser cette fonctionnalité.</p>
                <Button full variant={BUTTON_VARIANTS.REVERSE} style={{marginBottom:'12px'}} isLink to={createLink('userMeSettingsDating')}>Mes paramètres de rencontre</Button>
                <Button onClick={handleClose} full>Fermer</Button>
            </div>
        </Drawer>
    )
}

export default DrawerLocation;