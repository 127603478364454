import React from 'react';
import moment from "moment";
import './MemberCard.scss';
import { Link } from 'react-router-dom';
import createLink from '../../../../../libs/createLink';
import ProfileGenderInfo from '../../../profile/components/ProfileGenderInfo/ProfileGenderInfo';
import CertifiedBadge from '../../../../components2/CertifiedBadge';
import { apiCropImage } from '../../../../../config';

const distanceMapper = (distance) => {
    if (!distance) {
        return null;
    }
    if (distance < 5) {
        return `< 5 km`;
    }
    if (distance > 800) {
        return `> 800 km`;
    }
    return `${distance} km`;
}

function isLessThanXDays(lastActivityDate, days = 3) {
  if(!lastActivityDate) return false;
  const threeDaysAgo = moment().subtract(days, "days");
  return moment(lastActivityDate).isAfter(threeDaysAgo);
}

const isNewMember = (member) => {
    const now = new Date();
    const memberDate = new Date(member.createdAt);
    const diffTime = Math.abs(now - memberDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 7;
}

const MemberCard = ({ member, ...props }) => {
    const distanceStr = distanceMapper(member._distance);
    return (
        <Link {...props} className="member-card" to={createLink('userProfile', member)}>
            <img src={apiCropImage({url: member?.profile?.avatar, height:350, width:250})} alt='avatar'/>
            <div className="member-card-wrapper">
                <div className="member-card-header">
                    <div className='username'>{member.username} {!!member.isCertified && <CertifiedBadge user={member} />}</div>
                    <ProfileGenderInfo user={member}/> 

                </div>
                <div className="member-card-info">
                    {distanceStr && <div className="member-card-info-tag">{distanceStr}</div>}
                    {member.online && <div className="member-card-info-tag">En ligne</div>}
                    {isNewMember(member) && <div className="member-card-info-tag">Nouveau</div>}
                    {(!member.online && isLessThanXDays(member.lastActivity, 3)) && <div className="member-card-info-tag">Actif récemment</div>}
                    {member?.setting?.appearInDating && <div className="member-card-info-tag">Rencontre</div>}
                </div>
            </div>
        </Link>
    );
};

export default MemberCard;