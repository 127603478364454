import React, { useState } from 'react';
import './AgeConfirmation.scss';
import Drawer from '../Drawer/Drawer';
import Button, { BUTTON_VARIANTS } from '../UI/Button/Button';
import { clickConfirmedAge, generateUrlAgeConfirmation, updateConfirmedAge, USER_CURRENT_AGE_VERIFICATION_KEY, USER_CURRENT_KEY } from '../../../api/public-voissa/users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { faCircleNotch, faUserShield } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';

function updateNSFWFilters(newValue) {
    // Parcourt toutes les clés du sessionStorage
    Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith("filters-")) { 
            try {
                let filterData = JSON.parse(sessionStorage.getItem(key)); // Récupérer l'objet JSON
                
                if (filterData && filterData.hasOwnProperty("nsfw")) { 
                    filterData.nsfw = newValue; // Modifier la valeur de nsfw
                    sessionStorage.setItem(key, JSON.stringify(filterData)); // Sauvegarder la mise à jour
                }
            } catch (error) {
               
            }
        }
    });
}

const AgeConfirmationVoissa = ({ handleClose, urlOnClick }) => {
    const [error, setError] = useState(false);

    // Mutation pour exécuter la vérification d'âge via urlOnClick
    const { isLoading, mutate } = useMutation((data) => updateConfirmedAge(urlOnClick),
        {
            onSuccess: () => {
                updateNSFWFilters(true);
                window.location.reload();
            },
            onError: () => {
                setError(true);
            }
        }
    );

    const { mutate : clickConfirmeAgeMutate } = useMutation((data) => clickConfirmedAge(data));

    const handleConfirm = () => {
        setError(false);
        clickConfirmeAgeMutate({providerName : "voissa"});
        mutate();
    };

    return (
        <div className="age-confirmation">
            <div className="age-confirmation-header">
                <FontAwesomeIcon icon={faUserShield}/>
                <h2>Contenu réservé<br/> aux adultes</h2>
            </div>
           
                <p>
                    La loi française impose de vérifier votre majorité par un tiers de confiance.
                </p>
                <p>
                    Dans les prochains jours nous vous proposerons des solutions techniques pour le faire.
                </p>
                <p>
                    Des solutions simples et rapides vous seront proposées très bientôt.
                </p>
                <p>
                    Nous vous remercions de votre compréhension.
                </p>
                {error && <p className="age-confirmation-error">Une erreur est survenue. Veuillez réessayer.</p>}


            <Button 
                    variant={BUTTON_VARIANTS.REVERSE} 
                    onClick={handleConfirm} 
                    big 
                    full
                    loading={isLoading}
                    disabled={isLoading}
                    style={{marginBottom: "8px"}}
                >
                    {isLoading ? "Chargement..." : "Je suis majeur(e)"}
                </Button>
                <Button 
                    variant={BUTTON_VARIANTS.PRIMARY} 
                    onClick={handleClose} 
                    big 
                    full
                    disabled={isLoading}
                >
                    Peut-être plus tard
                </Button>
        </div>
    );
};

const AgeConfirmationProvider = ({ handleClose, listProviders }) => {
    const { isLoading, isSuccess, mutate : clickConfirmeAgeMutate } = useMutation((data) => clickConfirmedAge(data), {
        onSuccess: (res, {urlRedirect}) => {
            updateNSFWFilters(true);
            window.location.href = urlRedirect;
        }
    });
    return isLoading || isSuccess ? (
        <div className="age-confirmation">
            <div className="age-confirmation-loading">
                <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
            </div>
        </div>
    ) : (
        <div className="age-confirm-drawer">
            <div className="age-confirm-drawer-header">
                <FontAwesomeIcon icon={faUserShield}/>
                <h2>Contenu réservé<br/> aux adultes</h2>
            </div>
            <p className="age-confirm-drawer-text">
                On doit juste vérifier que vous avez plus de 18 ans, c’est la loi.<br/><br/>
                Pas d’inquiétude, c’est rapide, <b>sécurisé et sans aucune donnée conservée</b>.<br/><br/>
                Choisissez ce qui vous va le mieux : <b>carte d’identité, carte bancaire, ou selfie IA</b>.<br/><br/>
                Merci et bonne visite !<br/><br/>
                <small>Un problème ? Écrivez à <a href="mailto:assistance@voissa.com">assistance@voissa.com</a></small>
            </p>
            <div className="age-confirm-drawer-list">
                {Object.entries(listProviders).map(([provider, { urlRedirect }]) => (
                    <div key={provider}
                        className="age-confirm-drawer-list-item"
                        onClick={() =>{
                            clickConfirmeAgeMutate({providerName : provider, urlRedirect})
                        }}
                        >
                            <span>Valider mon âge avec {provider}</span>
                            <Button 
                                variant={BUTTON_VARIANTS.REVERSE} 
                                onlyIcon     
                                style={{marginLeft: "16px"}}
                            >
                                <FontAwesomeIcon icon={faCaretRight} />
                            </Button>
                    </div>
                ))}
            </div>
            <Button 
                variant={BUTTON_VARIANTS.PRIMARY} 
                onClick={handleClose} 
            >
                Peut-être plus tard
            </Button>
        </div>
    );
};

const AgeConfirmation = ({ handleClose }) => {
    const queryClient = useQueryClient();
    const dataCurrentUser = queryClient.getQueryData([USER_CURRENT_KEY]);
    const isProdiverVoissa = dataCurrentUser?.createdAt ? moment(dataCurrentUser?.createdAt).isBefore("2025-03-27") : false;
    const redirectUrl = window.location.href;

    const { data: listProviders = {}, isLoading, isError } = useQuery(
        [USER_CURRENT_AGE_VERIFICATION_KEY],
        () => generateUrlAgeConfirmation({ redirectUrl }),
        { staleTime: false }
    );

    // Ne garder que les autres providers si on n'est pas éligible à "voissa"
    const filteredProviders = isProdiverVoissa 
        ? {} 
        : Object.fromEntries(Object.entries(listProviders).filter(([key]) => key !== "voissa"));

    return (
        <Drawer open={true} handleClose={handleClose} small>
            {isLoading ? (
                <div className="age-confirmation">
                    <div className="age-confirmation-loading">
                        <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
                    </div>
                </div>
            ) : isError ? (
                <div className="age-confirmation">
                    <div className="age-confirmation-error">
                        <p>Impossible de récupérer les informations de vérification. Veuillez réessayer.</p>
                    </div>
                </div>
            ) : isProdiverVoissa && listProviders?.voissa?.urlOnclick ? (
                <AgeConfirmationVoissa 
                    handleClose={handleClose} 
                    urlOnClick={listProviders.voissa.urlOnclick} 
                />
            ) : (
                <AgeConfirmationProvider 
                    handleClose={handleClose} 
                    listProviders={filteredProviders} 
                />
            )}
        </Drawer>
    );
};

export default AgeConfirmation;
