import { useInfiniteQuery } from "@tanstack/react-query";
import _ from "lodash";
import ThumbnailsGrid from "../../components2/ThumbnailsGrid/ThumbnailsGrid";
import Button from "../../components2/UI/Button/Button";
import Container from "../../components2/UI/Container/Container";
import Group from "../../components2/UI/Group/Group";
import PageTitle from "../../components2/UI/PageTitle/PageTitle";
import { fetchMembers, MEMBERS_KEY } from "../../../api/public-voissa/members";
import MemberCard from "./components/MemberCard/MemberCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBowArrow, faRadar, faSignalBars, faSlider, faSparkle, faTransgender } from "@fortawesome/pro-light-svg-icons";
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Fragment, useContext, useState } from "react";
import useFilters from "../../../hooks/useFilters";
import Drawer from "../../components2/Drawer/Drawer";
import MenuList, { MenuListGroup, MenuListItem, MenuListItemCheck } from "../../components2/MenuList/MenuList";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoading from "../../components2/InfiniteScrollLoading/InfiniteScrollLoading";
import DrawerLocation from "./components/DrawerLocation/DrawerLocation";
import DrawerMeet from "./components/DrawerMeet/DrawerMeet";
import { ModalContext } from "../../components2/ModalManager";
import useRestriction from "../../../hooks/useRestriction";
import CONTENT_RESTRICTION_RULE from "../../../const/contentRestrictionRule";

let filters = {
    arroundMe : {
        multiple : false,
        defaultValue : false,
    },
    connected : {
        multiple : false,
        defaultValue : false,
    },
    openToMeet : {
        multiple : false,
        defaultValue : false,
    },
    newMembers : {
        multiple : false,
        defaultValue : false
    },
    gender : {
        multiple : true,
        uriParam : "gender",
        label : "Genre",
        defaultValue : [],
        options : [
            {
                label : "Les hommes",
                uriParam : "hommes",
                value : 1
            },
            {
                label : "Les femmes",
                uriParam : "femmes",
                value : 2
            },
            {
                label : "Les couples",
                uriParam : "couples",
                value : 3
            },
            {
                label : "Les Trans / Trav",
                uriParam : "trav-trans",
                value : 6
            }
        ]
    },
    ageRange : {
        multiple : false,
        uriParam : "ageRange",
        label : "Age",
        defaultValue : 0,
        options : [
            {
                label : "Tous les âges",
                value : 0
            },
            {
                label : "18-25 ans",
                value : 1
            },
            {
                label : "26-35 ans",
                value : 2
            },
            {
                label : "36-45 ans",
                value : 3
            },
            {
                label : "46-55 ans",
                value : 4
            },
            {
                label : "+ de 55 ans",
                value : 5
            }
        ]
    }
}

const isValidCoordinates = (lat, lng) => {
    if (lat && lng) {
        if (lat < -90 || lat > 90) {
            return false;
        }
        if (lng < -180 || lng > 180) {
            return false;
        }
        return true;
    }
    return false;
}

const Members = () => {
    const { openModal } = useContext(ModalContext);
    const currentUser = useCurrentUser();
    const {isRestricted, restrictionHandler} = useRestriction(CONTENT_RESTRICTION_RULE.MEMBERS_FILTER);
    const memberFilter = useFilters(filters, 'member-filter');
    const [toogleFiltersGender, setToogleFiltersGender] = useState(false);
    const [toogleFiltersAge, setToogleFiltersAge] = useState(false);
    const [toogleLocation, setToogleLocation] = useState(false);
    const [toogleMeet, setToogleMeet] = useState(false);
    // const [filter, setFilter] = useState({});


    const {data : members, fetchNextPage,isFetched, isLoading, hasNextPage} = useInfiniteQuery(
        [MEMBERS_KEY, memberFilter.apiFilter],
        ({pageParam = 1})=> fetchMembers({...memberFilter.apiFilter, page : pageParam}),
        {
            staleTime : (1000 * 60 * 1),
            getNextPageParam: (lastPage, pages) => {
                return lastPage && lastPage.more ? pages.length + 1 : undefined
            }
        }
    );

    // console.log(members);
    // const handleFilter = (filterName, value) => {
    //     if(value === undefined || value === null){
    //         setFilter(({[filterName] : _, ...rest}) => rest);
    //         return;
    //     }
    //     setFilter({
    //         ...filter,
    //         [filterName] : value
    //     });
    // }

    // const toggleFilter = (filterName) => {
    //     if(filter[filterName]){
    //         handleFilter(filterName, null);
    //     }else{
    //         handleFilter(filterName, 1);
    //     }
    // }
    const handleGuest = () => {
        openModal('Signin', {lockedAction : 'FILTER_MEMBERS'});
    }

    const handleRestricted = () => {
        restrictionHandler();
    }

    const handleFilterArroundMe = () => {
        if(!currentUser) return handleGuest();
        if(isRestricted) return handleRestricted();
        const [lat, lng] = currentUser?.location?.coordinate?.coordinates || [];
        if(!isValidCoordinates(lat, lng)) setToogleLocation(true);
        else memberFilter.toggleValue('arroundMe');   
    }
    const handleFilterConnected = () => {
        if(!currentUser) return handleGuest();
        if(isRestricted) return handleRestricted();
        memberFilter.toggleValue('connected');
    }
    const handleFilterOpenToMeet = () => {
        if(!currentUser) return handleGuest();
        if(isRestricted) return handleRestricted();
        if(!currentUser?.setting?.appearInDating) setToogleMeet(true);
        else memberFilter.toggleValue('openToMeet');
    }
    const handleFilterNewMembers = () => {
        if(!currentUser) return handleGuest();
        if(isRestricted) return handleRestricted();
        memberFilter.toggleValue('newMembers');
    }
    const handleFilterGender = () => {
        if(!currentUser) return handleGuest();
        setToogleFiltersGender(true);
    }
    const handleFilterAge = () => {
        if(!currentUser) return handleGuest();
        if(isRestricted) return handleRestricted();
        setToogleFiltersAge(true);
    }

    let listPages = _.get(members, 'pages') || [];
    let totalMembers = _.get(members, 'pages[0].total') || 0;
    let allData = [];
    if (members?.pages) {
        for (let page of members.pages) {
            allData = [...allData, ...page.data];
        }
    }
    
    return (
        <Container id="members">
            <DrawerLocation open={toogleLocation} handleClose={()=>setToogleLocation(false)}/>
            <DrawerMeet open={toogleMeet} handleClose={()=>setToogleMeet(false)}/>
            <Drawer 
                small
                open={toogleFiltersGender} 
                handleClose={()=>setToogleFiltersGender(false)} 
                >
                <MenuList style={{padding:'10px', paddingTop:'16px'}}>
                    <MenuListGroup label={filters.gender.label}>
                        {filters.gender.options.map((option, optionIndexe) => (
                            <MenuListItemCheck key={optionIndexe} onClick={()=> {
                                memberFilter.toggleValue('gender', optionIndexe);
                            }} active={memberFilter.getValue('gender').includes(option.value)}>{option.label}</MenuListItemCheck>
                        ))}
                    </MenuListGroup>              
                </MenuList>
            </Drawer>
            <Drawer 
                small
                open={toogleFiltersAge} 
                handleClose={()=>setToogleFiltersAge(false)} 
                >
                <MenuList style={{padding:'10px', paddingTop:'16px'}}>
                    <MenuListGroup label={filters.ageRange.label}>
                        {filters.ageRange.options.map((option, optionIndexe) => (
                            <MenuListItem key={optionIndexe} onClick={()=> {
                                memberFilter.setValue('ageRange', optionIndexe);
                            }} active={memberFilter.getValue('ageRange') === option.value}>{option.label}</MenuListItem>
                        ))}
                    </MenuListGroup>              
                </MenuList>
            </Drawer>
            <Container hasPadding>
                <PageTitle>Membres</PageTitle>
                <Group grid style={{marginBottom:'12px'}}>
                    <Button onClick={handleFilterGender} active={!!memberFilter.getValue('gender').length}><FontAwesomeIcon icon={faTransgender}/> Genre</Button>
                    <Button onClick={handleFilterArroundMe} active={memberFilter.getValue('arroundMe')}><FontAwesomeIcon icon={faRadar}/> Autour de moi</Button>
                    <Button onClick={handleFilterConnected} active={memberFilter.getValue('connected')}><FontAwesomeIcon icon={faSignalBars}/> Connecté</Button>
                    <Button onClick={handleFilterOpenToMeet} active={memberFilter.getValue('openToMeet')}><FontAwesomeIcon icon={faBowArrow}/> Ouvert à la rencontre</Button>
                    <Button onClick={handleFilterNewMembers} active={memberFilter.getValue('newMembers')}><FontAwesomeIcon icon={faSparkle}/> Nouveau</Button>
                    <Button onClick={handleFilterAge} active={!!memberFilter.getValue('ageRange')}><FontAwesomeIcon icon={faSlider}/> Age</Button>
                </Group>
            </Container>
            
            <InfiniteScroll
                dataLength={listPages.length}
                next={() => fetchNextPage()}
                hasMore={hasNextPage}
                loader={<InfiniteScrollLoading/>}
            > 
                <ThumbnailsGrid emptyMessage="Aucun membre trouvé  😞" hasData={totalMembers} initialized={!isLoading && isFetched}>
                    {listPages.map((data, page) =>(
                        <Fragment key={`page_${page}`}>
                            {data.data.map((member) => (
                                <MemberCard key={member._id} member={member}/>
                            ))}
                        </Fragment>
                    ))}
                </ThumbnailsGrid>
            </InfiniteScroll>
        </Container>
    );
    }
export default Members;