import { useState, useEffect, useRef } from 'react';

// const breakpoints = {
//     sm: 0,   // pour tout petit écran
//     md: 768, // à partir de 768px
//     lg: 1024 // à partir de 1024px
//   };

const useLocalBreakpoint = (breakpoints) => {
    const [matches, setMatches] = useState({});
    const elementRef = useRef(null);
  
    useEffect(() => {
      const handleResize = (entries) => {
        if (entries[0]) {
          const width = entries[0].contentRect.width;
  
          const newMatches = {};
          Object.keys(breakpoints).forEach((key) => {
            newMatches[key] = width <= breakpoints[key];
          });
  
          setMatches(prevMatches => {
            const isDifferent = Object.keys(newMatches).some(key => newMatches[key] !== prevMatches[key]);
            return isDifferent ? newMatches : prevMatches;
          });
        }
      };
  
      const observer = new ResizeObserver(handleResize);
      const element = elementRef.current;
  
      if (element) {
        observer.observe(element);
      }
  
      return () => {
        if (element) {
          observer.unobserve(element);
        }
      };
    }, [breakpoints]);
  
    return [matches, elementRef];
};

export default useLocalBreakpoint;
