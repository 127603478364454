
const CONTENT_RESTRICTION_RULE = {
    PROFILE_MEDIAS_LIMIT : "PROFILE_MEDIAS_LIMIT",
    PROFILE_COLLECTIONS_LOCKED : "PROFILE_COLLECTIONS_LOCKED",
    VIDEO_DURATION_LIMIT : "VIDEO_DURATION_LIMIT",
    VIEW_LAST_VISITORS: "VIEW_LAST_VISITORS",
    USER_BOOKMARKS_LIMIT: "USER_FAVORITES_LIMIT",
    USER_NOTIFICATIONS_LIMIT: "USER_NOTIFICATIONS_LIMIT",
    PAGE_LIMIT: "PAGE_LIMIT",
    PROFIL_COLLECTION_MEDIAS_LIMIT: "PROFIL_COLLECTION_MEDIAS_LIMIT",
    PROFIL_FEED_PUBLICATIONS_LIMIT: "PROFIL_FEED_PUBLICATIONS_LIMIT",
    ADD_TO_CIRCLE: "ADD_TO_CIRCLE",
    MESSENGER: "MESSENGER",
    CHAT_WEBCAM: "CHAT_WEBCAM",
    CREATE_PUBLICATION_MEDIA: "CREATE_PUBLICATION_MEDIA",
    MEMBERS_FILTER: "MEMBERS_FILTER"
}

export default CONTENT_RESTRICTION_RULE;