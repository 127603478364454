import React from 'react';
import './MenuList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';



export const MenuListItem = ({children, active, icon, loading, disabled,onClick, ...props}) => {
  const handleClick = (e) => {
    if (loading || disabled) {
        e.preventDefault();
        return;
    }
    if (onClick) {
        onClick(e);
    }
}
  return <li className={classNames('menu-list-item', {active})} {...props} onClick={handleClick}>
          {loading && <div className="menu-list-item-loading">
              <FontAwesomeIcon className="menu-list-item-loading-icon" icon={faSpinnerThird} spin />    
          </div>}
          {icon && <FontAwesomeIcon className="menu-list-item-icon" icon={icon}/> }
        {children}
  </li>;
};

export const MenuListItemCheck = ({children, active, ...props}) => {
  return <MenuListItem {...props}>
      <div className={classNames("menu-list-item-check", {active})}>
          {active && <FontAwesomeIcon icon={faCheck}/> }
      </div>
      {children}
  </MenuListItem>;
};

export const MenuListItemLink = ({children, icon, ...props}) => {
  return <Link className='menu-list-item menu-list-item-link' {...props}>
          {icon && <FontAwesomeIcon className="menu-list-item-icon" icon={icon}/> }
        {children}
  </Link>;
};

export const MenuListGroup = ({label,children}) => {
    return <li className='menu-list-group'>
        <div className='menu-list-group-title'>{label}</div>
        <ul>{children}</ul>
    </li>;
};

const MenuList = ({children, ...props}) => {
  return (
    <ul className='menu-list' {...props}>
      {children}
    </ul>
  );
};

export default MenuList;
