import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinnerThird
} from "@fortawesome/pro-solid-svg-icons";
import './ThumbnailsGrid.scss';

const ThumbnailsGrid = ({children, initialized=true, hasData=true, emptyMessage = 'Aucune publication à afficher 😞'})=>{
    const isEmptyChildren = !children || (Array.isArray(children) && !children.length);
    const dataIsEmpty = !hasData || (Array.isArray(hasData) && !hasData.length);
    const isEmpty = isEmptyChildren || dataIsEmpty;

    return (isEmpty || !initialized) ? (
        <div className="thumbnails-grid thumbnails-grid-no-content-wrapper" >
            <div className='thumbnails-grid-no-content'>
                {!initialized 
                    ? (<FontAwesomeIcon icon={faSpinnerThird} spin />)
                    : emptyMessage
                }
            </div>
        </div>
    ) : (
        <div className="thumbnails-grid" >
            {children}
        </div>
    )
}

export default ThumbnailsGrid;