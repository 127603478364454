import { useNavigate } from "react-router-dom";
import createLink from "../libs/createLink";
import { ModalContext } from "../ui/components2/ModalManager";
import useAbility from "./useAbility";
import useCurrentUser from './useCurrentUser';
import { useContext } from 'react';
import { useDrawer } from "../ui/components2/DrawerProvider";
import ContentLocked from "../ui/components2/ContentLocked/ContentLocked";
import CONTENT_RESTRICTION_RULE from "../const/contentRestrictionRule";
import USER_ROLES from "../const/userRoles";
import CertificationModal from "../ui/components2/CertificationModal/CertificationModal";

export const CURRENT_USER_LOCKED_LABEL = {
    ROLE_GUEST: 'ROLE_GUEST',
    ROLE_EMAIL_UNCONFIRMED: 'ROLE_EMAIL_UNCONFIRMED',
    ROLE_BANNED: 'ROLE_BANNED',
    ROLE_NO_PREMIUM: 'ROLE_NO_PREMIUM',
    USER_WARNING: 'USER_WARNING',
    ROLE_CAN_BE_PRIVILEGED: 'ROLE_CAN_BE_PRIVILEGED'
};

const MODERATED_RESTRICTION_CHECK = {
    [CONTENT_RESTRICTION_RULE.CREATE_PUBLICATION_MEDIA]: (user) => {
        return !!user?.forbidden?.createMedia;
    },
}

export const useCurrentUserLockedState = ({action}) => {
    const navigate = useNavigate();
    const {openDrawer, closeDrawer} = useDrawer();
    const currentUser = useCurrentUser();
    const currentRole = currentUser?.role || "guest";
    const { openModal } = useContext(ModalContext);
    const currentUserLockConditions = [
        {
            label: CURRENT_USER_LOCKED_LABEL.ROLE_GUEST,
            condition: (user, role) => !user?._id && role === "guest",
            defaultHandler: (contentOwner, action) => {
                openModal('Signin', {
                    lockedContentOwner : contentOwner, 
                    lockedReason : CURRENT_USER_LOCKED_LABEL.ROLE_GUEST, 
                    lockedAction : action
                });
            }
        },
        {
            label: CURRENT_USER_LOCKED_LABEL.USER_WARNING,
            condition: (user) => user?.isBan || MODERATED_RESTRICTION_CHECK[action]?.(user),
            defaultHandler: () => {
                openModal('WarningModal', {toggle : true, isModal : true, userId : currentUser?._id, username : currentUser?.username});
            }
        },
        {
            label: CURRENT_USER_LOCKED_LABEL.ROLE_EMAIL_UNCONFIRMED,
            condition: (user, role) => role === "emailUnconfirmed",
            defaultHandler: () => {
                openModal('EmailUnconfirmed', {toggle : true, isModal : true});
            }
        },
        {
            label: CURRENT_USER_LOCKED_LABEL.ROLE_BANNED,
            condition: (user, role) => role === "banned",
            defaultHandler: () => {
                navigate(createLink("signin"));
            }
        },
        {
            label: CURRENT_USER_LOCKED_LABEL.ROLE_CAN_BE_PRIVILEGED,
            condition: (user, role) => [2,3,4].includes(user?.profile?.gender) && !user?.subscription?.hasAccess, // 0=>NC, 1=>Homme, 2=>Femme, 3=>Couple, 4=>Couple lesbien, 5=>Couple gay, 6=>Travesti
            defaultHandler: () => {
                openDrawer(<CertificationModal handleCancel={closeDrawer}/>, {small : true});
            }
        },
        {
            label: CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM,
            condition: (user) => !user?.subscription?.hasAccess,
            defaultHandler: () => {
                navigate(createLink("subscription", currentUser));
            }
        }
    ];

    return currentUserLockConditions.find(({ condition }) => condition(currentUser, currentRole));
}

const useRestriction = (action, context, options = {}) => {
    const { cannot } = useAbility();
    const {openDrawer, closeDrawer} = useDrawer();
    const currentUser = useCurrentUser();
    const currentUserLockedState = useCurrentUserLockedState({action});
    const {restrictcionDisabled = false} = options;
    if(restrictcionDisabled) return { isRestricted: false };
    const isUserSignupAfterNewRestriction = currentUser && new Date('2024-10-26') < new Date(currentUser.createdAt);

    const handlers = {
        [CONTENT_RESTRICTION_RULE.CREATE_PUBLICATION_MEDIA]: () => {
            const cannotUploadMedia =  cannot({ action:'upload', subject: "Media" });
            const localLockedState = [CURRENT_USER_LOCKED_LABEL.ROLE_GUEST, CURRENT_USER_LOCKED_LABEL.USER_WARNING, CURRENT_USER_LOCKED_LABEL.ROLE_EMAIL_UNCONFIRMED, CURRENT_USER_LOCKED_LABEL.ROLE_BANNED].includes(currentUserLockedState?.label); 
            return {
                isRestricted :  localLockedState || cannotUploadMedia,
                restrictionReason: currentUserLockedState?.label || 'CANNOT_UPLOAD_MEDIA',
                restrictionHandler : () => {
                    currentUserLockedState.defaultHandler();
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.ADD_TO_CIRCLE]: (contentOwner) => {
            const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
            const canBePriviliged = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_CAN_BE_PRIVILEGED;
            return {
                isRestricted: cannot({ action: 'create', subject: 'Friend' }) || currentUserLockedState, 
                restrictionReason: currentUserLockedState?.label || 'CANNOT_CREATE_FRIEND',
                restrictionHandler : (e) => {
                    if(e?.nativeEvent instanceof Event) e.preventDefault();
                    if(premiumLocked || canBePriviliged){
                        openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.ADD_TO_CIRCLE} context={{canBePriviliged}} inDrawer={true} handleCancel={closeDrawer}/>, {small:true});
                    }
                    else currentUserLockedState.defaultHandler(contentOwner, action);
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.MEMBERS_FILTER]: () => {
            const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
            return {
                isRestricted: premiumLocked, 
                restrictionReason: 'CANNOT_FILTER_MEMBERS',
                restrictionHandler : (e) => {
                    if(premiumLocked){
                        if(e?.nativeEvent instanceof Event) e.preventDefault();
                        openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.MEMBERS_FILTER} inDrawer={true} handleCancel={closeDrawer}/>, {small:true});
                    }
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.MESSENGER]: (contentOwner = {}) => {
            const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
            const canBePriviliged = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_CAN_BE_PRIVILEGED;
            return {
                isRestricted: ![USER_ROLES.ADMINISTRATOR, USER_ROLES.MODERATOR].includes(contentOwner?.role) && currentUserLockedState, 
                restrictionReason: currentUserLockedState?.label,
                restrictionHandler : (e) => {
                    if(e?.nativeEvent instanceof Event) e.preventDefault();
                    if(premiumLocked || canBePriviliged){
                        openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.MESSENGER} context={{...contentOwner, canBePriviliged}} inDrawer={true} handleCancel={closeDrawer}/>, {small:true});
                    }
                    else currentUserLockedState.defaultHandler(contentOwner, action);
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.VIEW_LAST_VISITORS]: () => {
            const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
            const canBePriviliged = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_CAN_BE_PRIVILEGED;
            return {
                isRestricted: currentUserLockedState && isUserSignupAfterNewRestriction, 
                restrictionReason: currentUserLockedState?.label,
                restrictionHandler : () => {
                    if(premiumLocked || canBePriviliged){
                        openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.VIEW_LAST_VISITORS} inDrawer={true} context={{canBePriviliged}} handleCancel={closeDrawer}/>, {small:true});
                    }
                    else currentUserLockedState.defaultHandler();
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.CHAT_WEBCAM]: () => {
            const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
            const canBePriviliged = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_CAN_BE_PRIVILEGED;
            return {
                isRestricted: currentUserLockedState, 
                restrictionReason: currentUserLockedState?.label,
                restrictionHandler : () => {
                    if(premiumLocked || canBePriviliged){
                        openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.CHAT_WEBCAM} inDrawer={true} context={{canBePriviliged}} handleCancel={closeDrawer}/>, {small:true});
                    }
                    else currentUserLockedState.defaultHandler();
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.VIDEO_DURATION_LIMIT]: () => {
            const videoDurationLimit = 30;
            return {
                // isRestricted: currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_GUEST, 
                isRestricted: false, 
                restrictionReason: currentUserLockedState?.label,
                restrictionHandler : () => {
                    openDrawer(<ContentLocked restrictionRule={CONTENT_RESTRICTION_RULE.VIDEO_DURATION_LIMIT} context={{videoDurationLimit}} inDrawer={true} handleCancel={closeDrawer}/>, {small:true});
                },
                restrictionParams : {
                    duration: videoDurationLimit
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.USER_BOOKMARKS_LIMIT]: () => {
            const bookmarksLimit = 20;
            return {
                isRestricted: currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM && isUserSignupAfterNewRestriction, 
                restrictionReason: currentUserLockedState?.label,
                restrictionParams : {
                    limit: bookmarksLimit
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.USER_NOTIFICATIONS_LIMIT]: () => {
            const notificationLimit = 10;
            return {
                isRestricted: currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM && isUserSignupAfterNewRestriction, 
                restrictionReason: currentUserLockedState?.label,
                restrictionParams : {
                    limit: notificationLimit
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.PROFIL_FEED_PUBLICATIONS_LIMIT]: ({contentOwner}) => {
            if (!contentOwner || (contentOwner?._id === currentUser?._id)) return { isRestricted: false };
            const publicationLimitPremium = 40;
            const publicationLimitGuest = 10;
            // const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM && isUserSignupAfterNewRestriction;
            const guestLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_GUEST;
            return {
                // isRestricted: guestLocked || premiumLocked, 
                isRestricted: false, 
                restrictionReason: currentUserLockedState?.label,
                restrictionParams : {
                    limit: guestLocked ? publicationLimitGuest : publicationLimitPremium,
                    isGuestLocked: guestLocked
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.PROFILE_MEDIAS_LIMIT]: ({contentOwner}) => {
            if (!contentOwner || (contentOwner?._id === currentUser?._id)) return { isRestricted: false };
            const publicationLimitPremium = 20;
            const publicationLimitGuest = 10;
            // const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM && isUserSignupAfterNewRestriction;
            const guestLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_GUEST;
            return {
                // isRestricted: guestLocked || premiumLocked, 
                isRestricted: false, 
                restrictionReason: currentUserLockedState?.label,
                restrictionParams : {
                    limit: guestLocked ? publicationLimitGuest : publicationLimitPremium,
                    isGuestLocked: guestLocked
                }
            };
        },
        [CONTENT_RESTRICTION_RULE.PROFIL_COLLECTION_MEDIAS_LIMIT]: ({contentOwner}) => {
            if (!contentOwner || (contentOwner?._id === currentUser?._id)) return { isRestricted: false };
            const publicationLimitGuest = 10;
            // const premiumLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_NO_PREMIUM && isUserSignupAfterNewRestriction;
            const guestLocked = currentUserLockedState?.label === CURRENT_USER_LOCKED_LABEL.ROLE_GUEST;
            return {
                // isRestricted: guestLocked || premiumLocked, 
                isRestricted: false, 
                restrictionReason: currentUserLockedState?.label,
                restrictionParams : {
                    limit: publicationLimitGuest,
                    isGuestLocked: guestLocked
                }
            };
        }
    };

    const restrictionHandler = handlers[action];
    if (handlers[action]) {
        const currentHandler = restrictionHandler(context);
        if (currentHandler.isRestricted) {
            return currentHandler;
        }
        else {
            return { isRestricted: false };
        }
    }
    return { isRestricted: false };
};

export default useRestriction;