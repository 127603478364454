import "./ProfileGenderInfo.scss";
import React from "react";
import moment from "moment";
import _ from "lodash";
import { config } from "../../../../../config";
import { trancheAge } from "../../../../../libs/helper";

const COLOR_NEUTRAL = "#666666";
const COLOR_MALE = "#0c75ed";
const COLOR_MALE_DARKEN = "#4141bd";
const COLOR_FEMALE = "#f04f7d";
const COLOR_FEMALE_DARKEN = "#fe2525";

const ORIENTATION_MAP = {
  "Non renseigné": null,
  'Hétéro': null,
  'Bisexuelle': 'Bi'
};

const ProfileGenderInfo = ({ user }) => {
  let colorsSex = [];

  const gendersInfo = (_.get(user, "profile.informations") || []).map(
    (userInfo) => {
      const orientation = config.genderOrientation[userInfo.orientation];
      colorsSex.push(userInfo.sex === 1 ? COLOR_FEMALE : COLOR_MALE);
      return {
        orientation: ORIENTATION_MAP[orientation] !== undefined ? ORIENTATION_MAP[orientation] : orientation,
        ageRange: userInfo.birthday
          ? trancheAge(moment().diff(userInfo.birthday, "years"), false)
          : null,
      };
    }
  );

  if(colorsSex.length === 1){
    const colorSex = colorsSex[0];
    if (colorSex === COLOR_MALE) colorsSex.push(COLOR_MALE_DARKEN);
    else if (colorSex === COLOR_FEMALE) colorsSex.push(COLOR_FEMALE_DARKEN);
  }

  const gradientStyle = {
    backgroundColor: COLOR_NEUTRAL,
    background: `linear-gradient(45deg, ${colorsSex.join(",")})`,
  };

  return (
    <div className="profile-gender-info" style={gradientStyle}>
      <span className="profile-gender-info-label">{config.genderList[user.profile.gender]}</span>
      <span>
        {gendersInfo.map((genderInfo, i) => genderInfo.ageRange ? (
          <div key={i} className={"profile-gender-info-item"}>
            {genderInfo.ageRange}
            {!user?.setting?.notDisplayOrientationInProfile &&
            genderInfo.orientation
              ? ` ${genderInfo.orientation}`
              : ""}
          </div>
        ): null)}
      </span>
    </div>
  );
};

export default ProfileGenderInfo;
