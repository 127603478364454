import ability from "../ability";

const useAbility = () =>{
    const abilityFactory = ({action, subject : paramSubject, field, modelName, not = false})=>{
        const subject = (modelName && typeof paramSubject !== 'string') ? {constructor: {name: modelName}, ...paramSubject} : paramSubject;
        const can = not ? 'cannot' : 'can';
        if (Array.isArray(action)) {
            return action.reduceRight((acc, currentAction) => (ability[can](currentAction, subject, field) || !!acc), false);
        } else return ability[can](action, subject, field);
    }

    const can = (params)=>{
        return abilityFactory(params);
    }

    const cannot = (params)=>{
        return abilityFactory({...params, not:true});
    }

    return {can, cannot};
}

export default useAbility;