import React from 'react';
import './PageTitle.scss';
import classNames from 'classnames';

const PageTitle = ({heading = 1, children, className, ...props}) => {
  const Tag = `h${[1, 2, 3, 4].includes(heading) ? heading : 1}`;
  return (
    <Tag className={classNames('page-title', className)} {...props}>{children}</Tag>
  );
};

export default PageTitle;
