import { useCallback, useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";

// import queryString from  'qs';

// const flattenFilter = (filter) => {
//     const flatFilter = Object.keys(filter).reduce((acc, key) => {
//         if(!filter[key].value || filter[key].value?.length === 0) return acc;
//         acc[key] = filter[key].value;
//         return acc;
//     }, {});
//     const uriParam = Object.keys(flatFilter).reduce((acc, key) => {
//         acc[filter[key].uriParam] = flatFilter[key];
//         return acc;
//     }, {})
//     return {flatFilter, uriParam};
// }

// const useFiltersBK = (initFilter) => {
//     const [filter, setFilter] = useState(initFilter);
//     const [searchParams, setSearchParams] = useSearchParams();
//     const qs = queryString.parse(searchParams.toString());
  
//     const setValue = useCallback((name, value) => {
//         setFilter(prevFilter => ({ ...prevFilter, [name]: { ...prevFilter[name], value } }));
//     }, []);

//     const getValue = useCallback((name) => {
//         return filter[name].value;
//     }, [filter]);

//     const toggleValue = useCallback((name) => {
//         setFilter(prevFilter => ({ ...prevFilter, [name]: { ...prevFilter[name], value: !prevFilter[name].value } }));
//     }, []);
    
//     useEffect(() => {
//         const {uriParam} = flattenFilter(filter);
//         setSearchParams(uriParam, {replace: true});
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [filter]);
//     const {flatFilter} = flattenFilter(filter);
//     return {setValue, getValue, toggleValue, filter, flatFilter};
// }

const getInternalFilter = (filter) => {
    return Object.keys(filter).reduce((acc, key) => {
        acc[key] = filter[key].defaultValue;
        return acc;
    }, {});
}

const getApiFilter = (filter, initFilter) => {
    return Object.keys(filter).reduce((acc, key) => {
        if(filter[key]?.length === 0 || initFilter[key]?.apiExport === false || (filter[key] === initFilter[key]?.defaultValue && initFilter[key]?.apiExportDefault !== true)) return acc;
        acc[key] = filter[key];
        return acc;
    }, {});
};

const getStoredFilters = (name) => {
    const storedFilters = sessionStorage.getItem('filters-'+name);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
  };

const useFilters = (initFilter, name) => {
    const [filter, setFilter] = useState(getStoredFilters(name) || getInternalFilter(initFilter));
    // const [searchParams, setSearchParams] = useSearchParams();
    // const qs = queryString.parse(searchParams.toString());
    useEffect(() => {
        sessionStorage.setItem('filters-'+name, JSON.stringify(filter));
      }, [filter, name]);
  
    const setValue = useCallback((name, value) => {
        setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
    }, []);

    const toggleValue = useCallback((name, optionIndex = 0) => {
        setFilter(prevFilter => {
            const previousValue = prevFilter[name];
            if(Array.isArray(previousValue)){
                const optionValue = initFilter[name].options[optionIndex].value;
                if(previousValue.includes(optionValue)){
                    return { ...prevFilter, [name]: previousValue.filter(v => v !== optionValue)};
                }
                return { ...prevFilter, [name]: [...previousValue, optionValue]};
            }
            return { ...prevFilter, [name]: !previousValue};
        });
    }, [initFilter]);

    const getValue = useCallback((name, optionIndex = 0) => {
        return filter[name];
    }, [filter]);

    const apiFilter = getApiFilter(filter, initFilter);
    return {setValue, getValue, toggleValue, filter, apiFilter};
}

export const getFilter = (name)=> {
    const filter = getStoredFilters(name) || {};
    return {
        filter,
        getValue : (name) => {
            return filter[name];
        }
    }
}

export default useFilters;