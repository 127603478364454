const USER_ROLES = {
    ADMINISTRATOR : 'administrator',
    MODERATOR : 'moderator',
    EMAIL_UNCONFIRMED : 'emailUnconfirmed',
    STANDARD : 'standard',
    CREATOR : 'creator',
    BANNED : 'banned',
    GUEST : 'guest'
}

export default USER_ROLES;