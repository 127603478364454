import React from 'react';
import "./CertificationModal.scss";
import Button, { BUTTON_VARIANTS } from '../UI/Button/Button';
import useCurrentUser from '../../../hooks/useCurrentUser';
import createLink from '../../../libs/createLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const AVANTAGES = {
    HOMME : [
    ],
    FEMME_COUPLE : [
        "Accès à VMessenger",
        "Accès au Chat/Webcam",
        "Ajout au cercle de confiance",
    ]
}

const DESCRIPTIONS = {
    HOMME : "Profitez d'une meilleure visibilité ainsi qu'une confiance accrue.",
    FEMME_COUPLE : "Profitez d'une meilleure visibilité, d'une confiance accrue mais aussi de nombreux avantages."
}

const CertificationModal = ({handleCancel}) => {
    const currentUser = useCurrentUser();
    const isMale = currentUser?.profile?.gender === 1;
    const iconSrc = isMale ? require('./images/homme.png') : require('./images/femme.png');
    const CURRENT_AVANTAGES = isMale ? AVANTAGES.HOMME : AVANTAGES.FEMME_COUPLE;
    return (
        <div className="certification-modal">
            <img className="certification-modal-icon" src={iconSrc} alt="icone femme certification"/>
            <div className="certification-modal-title">
                Certifier mon profil
            </div>
            <div className="certification-modal-description">
                {isMale ? DESCRIPTIONS.HOMME : DESCRIPTIONS.FEMME_COUPLE}
            </div>
            <div className="certification-modal-list">
                {CURRENT_AVANTAGES.map((avantage, index) => (
                    <div key={index} className="certification-modal-list-item">
                        <div className="certification-modal-list-item-icon"><FontAwesomeIcon icon={faCheck}/></div>
                        {avantage}
                    </div>
                ))}
            </div>
            <div className="certification-modal-action">
                <Button variant={BUTTON_VARIANTS.REVERSE} big isLink onClick={handleCancel} to={createLink("userMeSettingsCertification", currentUser)}>Certifier mon profil</Button>
                <Button variant={BUTTON_VARIANTS.CTA_LIGHT} big full onClick={handleCancel}>Peut-être plus tard</Button>
            </div>
        </div>
    )
}

export default CertificationModal;